<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <v-card hover>
          <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
          <v-card-actions>
            <v-slider
              :hint="`first phrase | ${start}`"
              max="40701"
              min="0"
              persistent-hint
              v-model="start"
            ></v-slider> 
            <v-slider
              :hint="`last phrase | ${end}`"
              max="40701"
              min="0"
              persistent-hint
              v-model="end"
            ></v-slider>         
          </v-card-actions>
          <div id="chart">
            <apexchart type="heatmap" height="650" :options="chartOptions" :series="series"></apexchart>
          </div>
          <v-card-text>
            {{title}}<br/>
            Please allow some time for the visualization to render.<br/>
            For performance reasons, the maximum matrix size is 100x100. 
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'

  const api = '//bachelorspeak.com/api/matrix'

  export default {
    name: 'Matrix',
    data: () => ({
      title: 'loading...',
      dataGuide: [
        { ep: 1, season: 6, start: 0, end: 1790 },
        { ep: 2, season: 6, start: 1790, end: 3937 },
        { ep: 3, season: 6, start: 3937, end: 5807 },
        { ep: 4, season: 6, start: 5807, end: 7595 },
        { ep: 5, season: 6, start: 7595, end: 9301 },
        { ep: 6, season: 6, start: 9301, end: 10998 },
        { ep: 7, season: 6, start: 10998, end: 12736 },
        { ep: 8, season: 6, start: 12736, end: 14519 },
        { ep: 9, season: 6, start: 14519, end: 15862 },
        { ep: 10, season: 6, start: 15862, end: 17571 },
        { ep: 11, season: 6, start: 17571, end: 18972 },
        { ep: 12, season: 6, start: 18972, end: 19787 },
        { ep: 1, season: 7, start: 19787, end: 21997 },
        { ep: 2, season: 7, start: 21997, end: 23809 },
        { ep: 3, season: 7, start: 23809, end: 25361 },
        { ep: 4, season: 7, start: 25361, end: 27275 },
        { ep: 5, season: 7, start: 27275, end: 29182 },
        { ep: 6, season: 7, start: 29182, end: 30823 },
        { ep: 7, season: 7, start: 30823, end: 32750 },
        { ep: 8, season: 7, start: 32750, end: 34837 },
        { ep: 9, season: 7, start: 34837, end: 36225 },
        { ep: 10, season: 7, start: 36225, end: 38031 },
        { ep: 11, season: 7, start: 38031, end: 39777 },
        { ep: 12, season: 7, start: 39777, end: 40701 }
      ],
      phrases: [],
      series: [],
      chartOptions: {
        chart: {
          height: 650,
          type: 'heatmap',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            }
          }
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 0,
            useFillColorAsStroke: true,
          }
        },
        xaxis: {
          labels: {
            show: false
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          tooltip: {
            enabled: false,
            formatter: function(val, opts) {
              return 
            }
          },
          crosshairs: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          // TODO why is this not working
          tooltip: {
            enabled: false,
            formatter: function(val, opts) {
              return "..."
            }
          },
          crosshairs: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ["#1976D2"],
        title: {
          text: '',//'Cosine Similarity Matrix',
          style: {
            fontFamily: 'Inconsolata',
            color: '#1976D2'
          }
        },
        tooltip: {
          fillSeriesColor: false,
          theme: 'light',
          y: {
            title: {},
            formatter: function (val, opts) { return null }
          }
        }
      },
      startProxy: null,
      endProxy: null,
      loading: true,
    }),
  created: function () {
    this.debouncedLoadMatrix = _.debounce(this.loadMatrix, 500)
    const that = this
    this.chartOptions.tooltip.custom = function({series, seriesIndex, dataPointIndex, w}) {
      const range = that.end - that.start - 1
      return '<div>' +
        '<span>' + that.phrases[range - seriesIndex] + '</span>' + '<br/>' + 
        '<span>' + that.phrases[dataPointIndex] + '</span>' +
      '</div>'
    }
    function getRandomInt(min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
    this.start = getRandomInt(0, 40601)
    this.end = this.start + 50
  },
    computed: {
      start: {
        get () {
          return (this.startProxy === null) ? 682 : this.startProxy     
        },
        set (val) {
          this.startProxy = val
          if (this.end - val > 100 || val >= this.end) {
            this.end = val + 100
          }          
        }
      },
      end: {
        get () {
          return (this.endProxy === null) ? 742 : this.endProxy     
        },
        set (val) {
          this.endProxy = val
          if (val - this.start > 100 || val <= this.start) {
            this.start = val - 100
          }          
        }
      },
    },
    watch: {
      start: function () {
        this.loading = true
        this.title = "loading..."
        this.debouncedLoadMatrix()
      },
      end: function () {
        this.loading = true
        this.title = "loading..."
        this.debouncedLoadMatrix()
      }
    },
    methods: {
      findEpisode(start) {
        return this.dataGuide.find(x => x.start <= start && x.end > start) 
      },
      findPartOfEp(start, ep) {
        const range = ep.end - ep.start
        const thirds = range / 3
        const firstThird = ep.start + thirds
        const middle = firstThird + thirds
        if (start <= firstThird) {
          return 'beginning'
        } else if (start <= middle) {
          return 'middle'
        } else {
          return 'end'
        }
      },
      loadMatrix: function () {
        axios.get(api, {
          params: {
            start: this.start,
            end: this.end
          },
        }).then(({ data }) => {
          if (data) {
            const ep = this.findEpisode(this.start)
            const partOfEp = this.findPartOfEp(this.start, ep)
            this.title = `Currently viewing ${this.end - this.start} phrases from the ${partOfEp} of episode ${ep.ep}, season ${ep.season}`
            this.phrases = data.phrases
            this.series = data.matrix.map((x, i) => {
              return {
                name: i.toString(), 
                data: x.map(xx => {
                  return {
                    x: i,
                    y: xx
                  }
                })
              } 
            }).reverse()
            this.loading = false
          }
        })
      }
    }
  }
</script>

<style>
  .apexcharts-tooltip {
    background: #272727 !important; 
    color: #ffffff;
  }  
  .apexcharts-menu-item {
    background: #272727 !important; 
    color: #ffffff;
  }
  .apexcharts-heatmap-rect {
    height: 15.48 !important;
  }
</style>

